@import "./styles/variables.scss";

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $primary !important;
  display: flex;
  justify-content: center;
}

/* ✨ اللغة العربية (RTL) */
.rtl {
  direction: rtl;
  text-align: right;

  /* تعديل محاذاة العناصر */
  .text-start {
    text-align: right !important;
  }

  .text-end {
    text-align: left !important;
  }

  .float-start {
    float: right !important;
  }

  .float-end {
    float: left !important;
  }

  .ms-auto {
    margin-right: auto !important;
    margin-left: 0 !important;
  }

  .me-auto {
    margin-left: auto !important;
    margin-right: 0 !important;
  }
}

/* ✨ اللغة الإنجليزية (LTR) */
.ltr {
  direction: ltr;
  text-align: left;

  /* تعديل محاذاة العناصر */
  .text-start {
    text-align: left !important;
  }

  .text-end {
    text-align: right !important;
  }

  .float-start {
    float: left !important;
  }

  .float-end {
    float: right !important;
  }

  .ms-auto {
    margin-left: auto !important;
    margin-right: 0 !important;
  }

  .me-auto {
    margin-right: auto !important;
    margin-left: 0 !important;
  }
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* الصندوق الرئيسي للمحتوى */
#root {
  width: 100%;
  /* العرض الافتراضي */
  // max-width: 450px;
  border-radius: 10px;
  padding-top: 5px;

}

h1,
h2,
h3,
h4,
h5,
h6,
p,
label,
img,
input[readonly],
textarea[readonly],
input:disabled,
textarea:disabled {
  cursor: default !important;
  caret-color: transparent !important;
}


h1,
h2,
h3,
h4,
h5,
h6 {
  color: $fancycolor;
  margin-bottom: 1px;
}

h1 {
  font-size: 2rem !important;
}

h2 {
  font-size: 1.8rem !important;
}

h3 {
  font-size: 1.6rem !important;
}

h4 {
  font-size: 1.4rem !important;
}

h5 {
  font-size: 1.1rem !important;
}

h6 {
  font-size: 1rem !important;
}

@media screen and (max-width: 400px) {
  h1 {
    font-size: 1.5rem !important;
  }

  h2 {
    font-size: 1.3rem !important;
  }

  h3 {
    font-size: 1.2rem !important;
  }

  h4 {
    font-size: 1.1rem !important;
  }

  h5 {
    font-size: 1rem !important;
  }

  h6 {
    font-size: 0.9rem !important;
  }

}

p {
  color: $fontcolor !important;
}

a,
u {
  text-decoration: none;
}

.fontcolorWhite {
  color: $fontcolor !important;
}

.fontcolorYellow {
  color: $fancycolor !important;
}

.fontcolorPrimary {
  color: $primary !important;
}

.fontcolorYellowBack {
  background-color: $fancycolor;
}

.bgTransparent {
  background-color: #00000030;
}

.bgMain {
  background: $fancyback;
  background-image: none !important;
}

.borderYellow {
  border: 1px solid $fancycolor !important;
  border-radius: 10px !important;
}

.max-width-300 {
  max-width: 300px !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.fontSize-12-10 {
  font-size: 12px !important;
}

@media screen and (max-width: 400px) {
  .fontSize-12-10 {
    font-size: 10px !important;
  }
}

.body-game {
  background: $primary;
  background: $primary;
  left: 0;
  margin: 0 auto;
  max-width: 450px;
  position: absolute;
  right: 0;
  width: calc(100% - 10px);
  z-index: 1;
}

.container-game {
  min-height: 700px;
  max-height: 750px;
  max-width: 450px;
  margin: 2px auto;
  border: $fancycolor 1px solid;
  border-radius: 5px;
  height: 94vh;
  overflow: hidden;
  position: relative;
  grid-template: repeat(8, 1fr) / repeat(8, 1fr);
  background-image: linear-gradient(45deg, $primary 24%, $border 15%, $border 16%, transparent 27%, #bf030300 74%, $border 75%, $border 76%, $primary 77%, $primary),
    linear-gradient(135deg, $primary 24%, $border 15%, $border 16%, #8f7e7e00 27%, #5d3f3f00 74%, $border 75%, $border 76%, $primary 77%, $primary);
  background-size: 45px 45px;
}

/* ✅ فقط لمسار `/admin` */
body[data-page="admin"] .container-game {
  max-width: 800px !important;
}

.container-game:before {
  animation: gleam 300s linear infinite;
  animation-play-state: var(--enable-animation);
  background: linear-gradient(90deg, #ffffff12, #fff0 50%, #ffffff12);
  background-size: 40px 40px;
  content: "";
  display: grid;
  grid-template: repeat(8, 1fr) / repeat(8, 1fr);
  height: 1000px;
  left: 50%;
  overflow: hidden;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  width: 1000px;
  z-index: -1;
}

body {
  --enable-animation: running; // افتراضيًا تعمل الحركات
}

@keyframes gleam {
  from {
    background-position: 0 0;
  }

  to {
    background-position: 200% 0;
  }
}

/* التعديل حسب العرض */
@media (max-width: 460px) {
  #root {
    max-width: 95%;
    /* تقليل العرض ليأخذ نسبة من الشاشة */
  }
}

/* 🔥 تأثير الإضاءة الخلفية */
.glow-background {
  position: absolute;
  max-width: 700px;
  min-width: 300px;
  height: 200px;
  background: radial-gradient(circle, rgba(255, 255, 0, 0.3) 0%, rgba(255, 255, 0, 0) 70%);
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
  filter: blur(100px);
  z-index: 1;
}

/* 🌟 تأثير التوهج للنص */
.text-glow {
  text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.8),
               0px 0px 20px rgba(255, 255, 255, 0.6);
}

.height-50 {
min-height: 50vh;}

.button-icon {
  padding: unset !important;
  --offset: 1px !important;
  background: $primary;
  height: 30px;
  max-width: 100%;
  overflow: hidden;
  position: relative;
  border-radius: 5px !important;
}

.button-icon::before {
  content: "";
  position: absolute;
  aspect-ratio: 1;
  background: conic-gradient(#0000 270deg, #fff, #0000);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.button-icon::after {
  content: '';
  background: inherit;
  border-radius: inherit;
  position: absolute;
  inset: var(--offset);
  height: calc(100% - 2 * var(--offset));
  width: calc(100% - 2 * var(--offset));
}

.span-icon {
  position: relative;
  z-index: 1;
  color: $fancycolor !important;
  width: 1.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.span-icon-connected {
  position: relative;
  z-index: 1;
  color: $fontcolor !important;
  width: 1.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.span-icon input {
  background: transparent;
  color: white;
  font-size: 1.5rem;
  position: absolute;
  inset: 0;
  z-index: 10;
  padding: 1.5rem;
}

.rotate-right::before {
  animation: rotate-right 3s linear infinite;
  animation-play-state: var(--enable-animation);
}

.rotate-left::before {
  animation: rotate-left 3s linear infinite;
  animation-play-state: var(--enable-animation);
}

@keyframes rotate-right {
  from {
    transform: translate(-50%, -50%) scale(1.4) rotate(0turn);
  }

  to {
    transform: translate(-50%, -50%) scale(1.4) rotate(1turn);
  }
}

@keyframes rotate-left {
  from {
    transform: translate(-50%, -50%) scale(1.4) rotate(1turn);
  }

  to {
    transform: translate(-50%, -50%) scale(1.4) rotate(0turn);
  }
}

.button-download {
  position: fixed;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 10px;
  z-index: 1000;
  max-width: 90%;
  height: auto;
  text-align: center;
  border: 1px solid $fancycolor;
  border-radius: 10px !important;
  overflow: hidden;
  --offset: 3px;
  background: $fancyback;
}
// fancy
.fancy {
  --offset: 3px;
  background: $fancyback;
  border-radius: 50px;
  position: relative;
  height: 30px;
  max-width: 90px;
  max-width: 100%;
  overflow: hidden;
}

.fancy::before {
  content: '';
  background: conic-gradient(transparent 270deg, white, transparent);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  aspect-ratio: 1;
  width: 100%;
}

/* Overlay */
.fancy::after {
  content: '';
  background: inherit;
  border-radius: inherit;
  position: absolute;
  inset: var(--offset);
  height: calc(100% - 2 * var(--offset));
  width: calc(100% - 2 * var(--offset));
}

.fancy input {
  background: transparent;
  color: white;
  font-size: 1.5rem;
  position: absolute;
  inset: 0;
  z-index: 10;
  padding: 1.5rem;
}

.button {
  --offset: 1px !important;
}

.popup {
  position: absolute;
  bottom: 45px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;
  max-width: 400px;
  width: 90%;
  background: $primary;
  border: 1px solid $fancycolor;
}

.color-input {
  width: 30px;
  height: 30px;
  border: 1px solid $fancycolor;
  border-radius: 5px;
  margin: 5px;
  cursor: pointer;
}

.fancycam1 {
  height: 100px;
  border: 1px solid $fancycolor;
  border-radius: 10px !important;
  overflow: hidden;
  --offset: 3px;
  background: $fancyback;
  position: relative;
}

.fancycam2 {
  height: 100px !important;
  border: 1px solid $fancycolor;
  border-radius: 10px !important;
  overflow: hidden;
  --offset: 3px;
  background: $fancyback;
  position: relative;
}

.popup-voiting {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.ask_audience {
  height: 630px !important;
  border: 1px solid $fancycolor;
  border-radius: 10px !important;
  width: 400px;
  max-width: 100%;
  overflow: hidden;
  --offset: 3px;
  background: $fancyback;
  position: absolute;
  bottom: 45px;
}

.bg-success {
  animation-name: flash;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@keyframes flash {

  0%,
  50%,
  100% {
    opacity: 1;
  }

  25%,
  75% {
    opacity: 0;
  }
}

.flash {
  animation-name: flash;
}


/* Popup CSS for cam1 */
.cam1-popup .popup-likes-overlay {
  position: absolute;
  /* Changed from fixed to absolute */
  top: 0;
  left: 0;
  width: 100%;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  background: $primary;
}

/* Popup CSS for cam2 */
.cam2-popup .popup-likes-overlay {
  position: absolute;
  /* Changed from fixed to absolute */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-likes-content {
  padding: 10px 5px;
  border-radius: 8px;
  height: 90px;
  text-align: center;
  background: $fancyback;
}

.popup-likes-content-cam2 {
  padding: 4px;
  border-radius: 8px;
  text-align: center;
  background: $fancyback;
}

.popup-likes-image {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  margin-bottom: 5px;
  margin-top: 5px;
}

.popup-gift-image {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-bottom: 5px;
}


.likers-container {
  overflow: hidden;
  width: 100%;
  display: flex;
  justify-content: center;
}

.likers-slider {
  display: flex;
  transition: transform 0.5s ease-in-out;
  width: 100%;
}

.liker-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 0px 10px;
}

.profile-pic {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-bottom: 1px;
  border: 3px solid transparent;
  /* ✅ الإطار سيكون مخصص فقط لأول 3 */
}

.rest-profile-pic {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-bottom: 1px;
  border: 3px solid transparent;
  /* ✅ الإطار سيكون مخصص فقط لأول 3 */
}

.profile-pic-samesize {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-bottom: 1px;
  border: 3px solid transparent;
  /* ✅ الإطار سيكون مخصص فقط لأول 3 */
}

/* ✅ الألوان لأول 3 مراكز */
.gold-border {
  border-color: gold;
  color: gold !important;
}

.silver-border {
  border-color: silver;
  color: silver !important;
}

.bronze-border {
  border-color: #cd7f32;
  color: #cd7f32 !important;
}

/* ✅ تقصير الاسم ليكون على سطر واحد */
.nickname {
  font-size: 12px;
  max-width: 70px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media screen and (max-width: 400px) {

  .nickname {
    font-size: 8px;
    max-width: 60px;
    /* 🔥 تحديد عرض الاسم */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    /* 🔥 إخفاء النص الطويل بـ "..." */
  }
}

.likes {
  color: red;
  font-weight: bold;
  font-size: 10px;
  display: inherit;
}

.tooltip-container {
  position: relative;
  cursor: pointer;
  transition: all 0.2s;
  font-size: 17px;
  border-radius: 10px;
  width: 55px;
  margin: 0 auto;
}

.tooltip {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s;
  border-radius: 15px;
  box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.2),
    inset -5px -5px 15px rgba(255, 255, 255, 0.1),
    5px 5px 15px rgba(0, 0, 0, 0.3), -5px -5px 15px rgba(255, 255, 255, 0.1);
  text-align: left !important;
}

.profile {
  background: #000;
  border-radius: 10px 15px;
  padding: 10px;
  border: 1px solid #52382f;
}

.tooltip-container:hover .tooltip {
  top: -150px;
  opacity: 1;
  visibility: visible;
  pointer-events: auto;

}

.icon {
  text-decoration: none;
  color: #fff;
  display: block;
  position: relative;
}

.layer {
  width: 55px;
  height: 55px;
  transition: transform 0.3s;
}

.icon:hover .layer {
  transform: rotate(-35deg) skew(20deg);
}

.layer span {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border: 1px solid #fff;
  border-radius: 15px;
  transition: all 0.3s;
}

.layer span,
.text {
  color: #00aaff;
  border-color: #00aaff;
}

.icon:hover.layer span {
  box-shadow: -1px 1px 3px #00aaff;
}

.icon .text {
  position: absolute;
  left: 50%;
  bottom: -5px;
  opacity: 0;
  font-weight: 500;
  transform: translateX(-50%);
  transition: bottom 0.3s ease, opacity 0.3s ease;
}

.icon:hover .text {
  bottom: -35px;
  opacity: 1;
}

.icon:hover .layer span:nth-child(1) {
  opacity: 0.2;
}

.icon:hover .layer span:nth-child(2) {
  opacity: 0.4;
  transform: translate(5px, -5px);
}

.icon:hover .layer span:nth-child(3) {
  opacity: 0.6;
  transform: translate(10px, -10px);
}

.icon:hover .layer span:nth-child(4) {
  opacity: 0.8;
  transform: translate(15px, -15px);
}

.icon:hover .layer span:nth-child(5) {
  opacity: 1;
  transform: translate(20px, -20px);
}

.tiktokSVG {
  font-size: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #000;
}

.user {
  display: flex;
  gap: 10px;
}

.imgTik {
  width: 50px;
  height: 50px;
  font-size: 25px;
  font-weight: 700;
  border: 1px solid #00aaff;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url('../public/images/brain.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.name {
  font-size: 17px;
  font-weight: 700;
  color: #00aaff;
}

.details {
  display: flex;
  flex-direction: column;
  gap: 0;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
}

.about {
  color: #ccc;
  padding-top: 5px;
  font-size: 14px;
}

.water {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  text-transform: uppercase;
  font-size: 1.8em;
  font-family: Arial, sans-serif;
  font-weight: bolder;
}

.waterName {
  bottom: 0;
  top: auto;
  font-size: 1.2rem;
  width: 100%;
  text-align: center;
}

.water:nth-child(1) {
  color: #ffffff66;
}

.water:nth-child(2) {
  color: $fancycolor;
  animation: water 6s ease-in-out infinite;
}

@keyframes water {

  0%,
  100% {
    clip-path: polygon(0 47%, 8% 36%, 19% 27%, 31% 29%, 42% 45%, 48% 66%, 58% 79%, 76% 79%, 89% 75%, 100% 63%, 100% 100%, 0 100%);
  }

  50% {
    clip-path: polygon(0 64%, 8% 76%, 19% 80%, 31% 78%, 42% 68%, 48% 46%, 58% 30%, 76% 28%, 89% 37%, 100% 48%, 100% 100%, 0 100%);
  }
}

@media screen and (max-width: 420px) {
  .water {
    font-size: 1.4em;
    top: 5px;
  }

  .waterName {
    bottom: 0;
    top: auto;
    font-size: 0.8rem
  }
}



.quesText {
  position: relative;
  text-align: center;
  top: 10px;
  width: 90%;
  margin: 0 auto;
  z-index: 1;
  animation: fill 1.5s infinite alternate;
}

@keyframes fill {
  from {
    -webkit-text-fill-color: $fancycolor;
  }

  to {
    -webkit-text-fill-color: white;
  }
}

.answer {
  margin: 5px auto;
  max-width: 300px !important;
  height: 40px !important;
  border-radius: 10px !important;
  max-width: 100%;
  overflow: hidden;
  --offset: 3px;
  background: $fancyback;
  border: $fancycolor 1px solid;
}

.answerSpan {
  position: inherit;
  z-index: 1;
  float: inline-start;
  margin: 9px 19px 9px 9px;
}

.answerMem {
  position: relative;
  z-index: 1;
  float: inline-start;
  margin: 10px auto;
  width: 100%;
}

.fade-in {
  position: absolute;
  position: relative;
  animation: fadeInAnimation 1s linear forwards;
}



@keyframes fadeInAnimation {
  0% {
    transform: translateX(-100vw);
    /* Initial position */
  }

  100% {
    transform: translateX(0);
    /* Move to the left of the viewport */
  }
}

.fade-out {
  position: absolute;
  position: relative;
  animation: fadeOutAnimation 1s linear forwards;
}

@keyframes fadeOutAnimation {
  0% {
    transform: translateX(100vw);
    /* Move to the left of the viewport */
  }

  100% {
    transform: translateX(0);
    /* Initial position */
  }
}

.bg-success {
  animation-name: flash;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@keyframes flash {

  0%,
  50%,
  100% {
    opacity: 1;
  }

  25%,
  75% {
    opacity: 0;
  }
}

.flash {
  animation-name: flash;
}

.nums {
  position: absolute;
  z-index: 1;
  color: white;
  left: 21px;
  margin: auto;
  top: 12px;
}

.ques {
  margin: auto;
  max-width: 300px !important;
  height: 150px !important;
  border-radius: 20px !important;
}

.ques-box {
  position: relative;
  max-width: 300px;
  pointer-events: none;
  margin: 0 auto;
  top: -20px;
}

.quesnum {
  position: absolute;
  width: 150px !important;
  height: 35px ;
  border-radius: 10px !important;
  --offset: 1px !important;
  background: $fancycolor !important;
  right: 20px;
}


.questime {
  position: absolute;
  width: 50px !important;
  height: 35px ;
  border-radius: 10px !important;
  --offset: 1px !important;
  background: $fancycolor !important;
  left: 20px;
}

.info {
  margin-top: 0;
  margin-bottom: 0;
}

.numberQues {
  color: white;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 18px;
  font-weight: 800;
  z-index: 1;
}


// timer
.numbertime {
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  font-weight: 900;
  transition: all 0.25s ease-in-out;
  text-shadow: 0px 0px 0px white,
    0px 0px 0px white;
  z-index: 1;
}



// Reset button
.container-game .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: $primary;
  background-color: $fancycolor;
  border-color: var(--bs-nav-tabs-link-active-border-color);
}

.container-game .nav-link {
  color: $fontcolor;
  --bs-nav-tabs-link-active-color: $primary;
}

.container-game.nav-link:focus,
.nav-link:hover {
  color: $fancycolor;
}



// scrollbar sider
.container-game .custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: $fancycolor transparent;
  max-height: 350px;
  overflow-y: auto;
}

.container-game .custom-scrollbar::-webkit-scrollbar {
  width: 8px;
}

.container-game .custom-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}

.container-game .custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: $fancycolor;
  border-radius: 10px;
}

// scrollbar result
.container-game .custom-scrollbar-result {
  scrollbar-width: thin;
  scrollbar-color: $fancycolor transparent;
  max-height: 280px;
  overflow-y: auto;
}

.container-game .custom-scrollbar-result::-webkit-scrollbar {
  width: 8px;
}

.container-game .custom-scrollbar-result::-webkit-scrollbar-track {
  background: transparent;
}

.container-game .custom-scrollbar-result::-webkit-scrollbar-thumb {
  background-color: $fancycolor;
  border-radius: 10px;
}

// leaderboard
.container-game .leaderboard-list {
  max-height: 240px;
  z-index: 1;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 90%;
}

/* Podium positioning */
.podium {
  display: flex;
  align-items: flex-end;
  opacity: 0;
  transform: translateY(400px);
  transition: opacity 1.5s ease-out, transform 4.5s ease-out;
}

.podium.show {
  opacity: 1;
  transform: translateY(20px);
}

.position-1 {
  order: 2;
  /* Center */
  transform: translateY(-20px);
  /* Higher */
}

.position-2 {
  order: 3;
  /* Right */
  transform: translateY(0);
  /* Lower */
}

.position-3 {
  order: 1;
  /* Left */
  transform: translateY(0);
  /* Lower */
}

.slow-motion {
  animation: fade-in 0.5s ease-in-out;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.crown {
  position: absolute;
  width: 40px;
  top: -24px;
  left: calc(50% - 20px);
  margin-bottom: 5px;
  animation: moveCrown 4s infinite alternate;
  z-index: 1;
}

@keyframes moveCrown {
  0% {
    transform: translateX(-30%) rotate(330deg);
    /* Start position */
  }

  10% {
    transform: translateX(-20%) rotate(340deg);
  }

  20% {
    transform: translateX(-10%) rotate(350deg);
  }

  25% {
    transform: translateX(0) rotate(0deg);
  }

  30% {
    transform: translateX(10%) rotate(10deg);
  }

  40% {
    transform: translateX(20%) rotate(20deg);
  }

  50% {
    transform: translateX(30%) rotate(30deg);
  }

  60% {
    transform: translateX(20%) rotate(20deg);
  }

  70% {
    transform: translateX(10%) rotate(10deg);
  }

  75% {
    transform: translateX(0) rotate(0deg);
  }

  80% {
    transform: translateX(-10%) rotate(350deg);
  }

  90% {
    transform: translateX(-20%) rotate(340deg);
  }

  100% {
    transform: translateX(-30%) rotate(330deg);
    /* Back to start position */
  }
}



.bgp {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
}

.bgm {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
}

svg {
  width: 100px;
}

.bgp1,
.bgp2,
.bgp3 {
  position: relative;
  /* Ensure relative positioning for absolute pseudo-element */
  height: 150px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-blend-mode: difference;
  filter: blur(0px) contrast(100) brightness(40);
  mix-blend-mode: lighten;
  z-index: 1;
}

.bgm1 {
  position: relative;
  margin-right: 25px;
  margin-top: -70px;
  width: 120px;
  height: 200px;
  background: repeating-linear-gradient(#ffffff, #111 50%, var(--bg1-color) 50%, var(--bg2-color));
  background-size: 5px 5px;
  filter: url(#noise);
  z-index: 3;
  top: 5px;
}

.bgm2 {
  position: relative;
  margin-right: -20px;
  margin-top: -70px;
  width: 100px;
  height: 200px;
  background: repeating-linear-gradient(#ffffff, #111 50%, var(--bg3-color) 50%, var(--bg4-color));
  background-size: 5px 5px;
  filter: url(#noise);
  z-index: 2;
}

.bgm3 {
  position: relative;
  margin-right: -20px;
  margin-top: -70px;
  width: 120px;
  height: 200px;
  background: repeating-linear-gradient(#ffffff, #111 50%, var(--bg5-color) 50%, var(--bg6-color));
  background-size: 5px 5px;
  filter: url(#noise);
  z-index: 1;
}

.bgm4 {
  position: relative;
  margin-right: -20px;
  margin-top: -60px;
  width: 120px;
  height: 200px;
  background: repeating-linear-gradient(#ffffff, #111 50%, var(--bg7-color) 50%, var(--bg8-color));
  background-size: 5px 5px;
  filter: url(#noise);
}

.numhideP {
  position: absolute;
  color: #ffffff1c !important;
  transition: color 0.5s ease, top 0.5s ease;
  z-index: 4;
}

.hide-bgm .bgm1,
.hide-bgm .bgm2,
.hide-bgm .bgm3,
.hide-bgm .bgm4 {
  display: none;
}

.show-numhideP {
  color: white !important;
  top: 25px;
}

#ques h1 {
  font-size: clamp(4rem, 20vw, 5rem) !important;
}


/* Add these styles to your CSS file */
@keyframes moveDigit {
  0% {
    transform: translateY(-30px);
  }

  100% {
    transform: translateY(30px);
  }
}

.digit {
  position: relative;
  display: inline-block;
}

.move {
  animation-name: moveDigit;
  animation-duration: 2s;
  /* Default duration */
  animation-iteration-count: infinite;
  animation-direction: alternate;
}



.shape {
  width: 345px;
  height: 345px;
  display: inline-block;
  margin: 10px;
}

.shape.circle {
  border-radius: 50%;
}

.shape.triangle {
  width: 0;
  height: 0;
  border-left: 172.5px solid transparent;
  border-right: 172.5px solid transparent;
  border-bottom: 345px solid;
}

.shape.diamond {
  transform: rotate(45deg);
  width: 244px;
  /* sqrt(2) * 345 / 2 */
  height: 244px;
  /* sqrt(2) * 345 / 2 */
}

.shape.star {
  width: 0;
  height: 0;
  margin-left: 172.5px;
  border-right: 100px solid transparent;
  border-bottom: 70px solid;
  border-left: 100px solid transparent;
  position: relative;
  top: -45px;
}

.shape.star:before {
  width: 0;
  height: 0;
  border-right: 100px solid transparent;
  border-bottom: 70px solid;
  border-left: 100px solid transparent;
  position: absolute;
  content: '';
  top: -40px;
  left: -100px;
  transform: rotate(-35deg);
}

.shape.star:after {
  width: 0;
  height: 0;
  border-right: 100px solid transparent;
  border-bottom: 70px solid;
  border-left: 100px solid transparent;
  position: absolute;
  content: '';
  top: -30px;
  left: -100px;
  transform: rotate(35deg);
}

.popup {
  position: absolute;
  bottom: 45px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;
  max-width: 400px;
  width: 90%;
}

.popup-settings {
  background: $primary;
  border: 1px solid $fancycolor;
}

/* Popup CSS for cam1 */
.cam1-popup .popup-likes-overlay {
  position: absolute;
  /* Changed from fixed to absolute */
  top: 0;
  left: 0;
  width: 100%;
  height: 125px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  background: $primary;
}

/* Popup CSS for cam2 */
.cam2-popup .popup-likes-overlay {
  position: absolute;
  /* Changed from fixed to absolute */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-likes-content {
  padding: 20px 5px;
  border-radius: 8px;
  height: 125px;
  text-align: center;
  background: $fancyback;
}

.popup-likes-content-cam2 {
  padding: 4px;
  border-radius: 8px;
  text-align: center;
  background: $fancyback;
}

.popup-likes-image {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  margin-bottom: 5px;
  margin-top: 5px;
}

.popup-gift-image {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-bottom: 5px;
}

/* GameWrapper.scss */

.liker-avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
}

.liker-nickname {
  font-weight: bold;
  margin-right: 10px;
}

.liker-likes {
  color: #ff0000;
}

.likes-popup {
  background: rgba(0, 0, 0, 0.8);
  border-radius: 8px;
  padding: 10px;
  position: absolute;
  bottom: 50px;
  left: 5px;
  z-index: 10000;
  width: 300px;
  max-height: 500px;

}

.popup-likes-overlay {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}


.likes-popup-header {
  text-align: center;
  margin-bottom: 10px;
}

.likes-popup-content {
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  li {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }
}

.transition-radio input[type="radio"] {
  transition: transform 0.3s ease;
}

.transition-radio input[type="radio"]:checked {
  transform: scale(1.1);
}

.right-side {
  list-style-type: none;
  padding: 0px;
  max-height: 450px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: $fancycolor transparent;
  animation: fade-in 0.5s ease-in-out;
  direction: ltr;
}

.left-side {
  list-style-type: none;
  padding: 0px;
  max-height: 450px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: $fancycolor transparent;
  animation: fade-in 0.5s ease-in-out;
}

.popup-overlay-leaders {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: start;
  padding-top: 50px;
  z-index: 999;
}

.popup-content-wrapper-leaders {
  background: $primary;
  padding: 20px;
  border-radius: 10px;
  color: white;
  text-align: center;
  max-width: 300px;
  width: 90%;
}

.leaders {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 10px;
}

.leader {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.leader img {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-bottom: 5px;
}



.leaderboard-list-teams {
  max-height: 240px;
  overflow-y: auto;
  z-index: 1;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 70%;
}

.card-img,
.card-img-bottom,
.card-img-top {
  width: 100px;
  object-fit: cover;
}

.color-picker {
  max-width: 380px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  .color-input-row {
    display: flex;
    justify-content: space-between;
    gap: 5px;

    input[type='color'] {
      width: 40px;
      height: 40px;
      padding: 0;
      border: none;
      cursor: pointer;
      border-radius: 5px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
      transition: transform 0.2s ease, box-shadow 0.2s ease;

      &:active {
        transform: scale(0.9);
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
      }
    }
  }

  .color-picker-buttons {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;

    &.btn-primary {
      background-color: $primary;
      color: $fontcolor;

      &:hover {
        background-color: #6b3cc5;
      }

      &:active {
        transform: scale(0.9);
      }
    }

    &.btn-secondary {
      background-color: $border;
      color: $fontcolor;

      &:hover {
        background-color: $primary;
      }

      &:active {
        transform: scale(0.9);
      }
    }
  }
}



.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 999;
}

.popup-content-wrapper {
  background: $primary;
  border: 1px solid $fancycolor;
  border-radius: 8px;
  text-align: center;
}

.icon-wrapper {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 255, 255, 0);
}

.answer-option {
  transition: transform 0.2s ease, background-color 0.2s ease;
}

.answer-option:hover {
  transform: scale(1.05);
  background-color: $fancycolor;
  color: $fancycolor;
}


.next-question-button {
  transition: transform 0.2s ease, background-color 0.2s ease;
  width: 100%;
  color: $fontcolor;

  &:hover {
    transform: scale(1.05);
    background-color: $fancycolor;
    color: $fontcolor;
    font-weight: bold;
  }
}

.quesnum-million {
  position: absolute !important;
  top: 350px;
  left: calc(50% + 80px);
  transform: translateX(-50%);
  margin: auto;
  width: 120px !important;
  height: 35px !important;
  border-radius: 10px !important;
  --offset: 1px !important;
  background: $fancycolor !important;
}

.numberQuesMillion {
  color: white;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 18px;
  font-weight: 800;
  z-index: 1;
}

.million_earning {
  position: absolute;
  width: 100px !important;
  height: 35px !important;
  border-radius: 10px !important;
  --offset: 1px !important;
  background: $fancycolor !important;
  left: 20px;
}

.numbertimeMillion {
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  font-weight: 900;
  transition: all 0.25s ease-in-out;
  text-shadow: 0px 0px 0px white,
    0px 0px 0px white;
  z-index: 1;
}

.element-dif {
  height: 35px !important;
  border-radius: 10px !important;
  --offset: 1px !important;
  background: $fancycolor !important;
}

.element-fancy {
  position: relative;
  overflow: hidden;
}

.celebration {
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  height: auto;
  width: 100%;
}

.top3-gifters-container {
  display: flex;
  justify-content: center;
  align-items: end;
  text-align: center;
  gap: 10px;
}

.gifter-container {
  position: relative;
  text-align: center;
}

.first-place {
  transform: scale(1.1);
}

.crown-img {
  position: absolute;
  width: 60px;
  top: -35px;
  left: 50%;
  transform: translateX(-50%);
}

.gifter-name {
  word-break: break-all;
  max-width: 95%;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 0;
}

/* ✅ تحسين التصميم عند عرض الشاشة أقل من 400 بكسل */
@media (max-width: 400px) {
  .profile-pic {
    width: 35px;
    height: 35px;
  }

  .rest-profile-pic {
    width: 35px;
    height: 35px;
  }

  .crown-img {
    width: 40px;
    top: -25px;
  }

  .gifter-name {
    font-size: 10px;
  }

  .quesnum {
    width: 120px !important;
  }

  .questime {
    width: 40px !important;
  }

  .leaderboard-list-teams {
    width: 60%;
  }
}
